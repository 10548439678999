import { useCallback, useContext, useEffect } from "react";
import { MessageTypes } from "../../../../../components/toast-message/toast-message";
import {
  useCountUserMonthlyWorkPlanLazyQuery,
  useGetClosingInfoLazyQuery,
  useGetClosingInfoToExcelLazyQuery,
  useGetUserMonthlyWorkExcelPlanLazyQuery,
  useSendClosingInfoMutation,
  useSendErpInfoMutation
} from "../../../../../generated/graphql";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import { useReactiveVar } from "@apollo/client";
import MainViewContext from "../../../../../components/main-view/store";
import { useSearchDateRange } from "../../../../../components/main-view/WorkManagementInfo/hooks";
import { TABS_DB_SCHEME_OBJECT } from "../../../../../components/main-view/WorkManagementInfo/logics";
import { indexDBTabName } from "./constants";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import { downloadFileFromServer } from "../../../../../components/main-view/statistics/Utils";
import moment from "moment";

export let prevEmployeeIdList: string[] = [];

function useClosingInfoDataFetcher(isVisibility: boolean) {
  const rootStore = useContext(MainViewContext);

  const {
    signInReducer: { loginIdInfo: authCheck, employee_id: callEmployeeId }
  } = rootStore;

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    saveSearchDate,
    retryRender,
    setRetryRender
  } = useSearchDateRange(TABS_DB_SCHEME_OBJECT.closingInfo, indexDBTabName);

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const [getClosingInfo, { data, loading }] = useGetClosingInfoLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 월별 근무 계획을 불러오지 못했습니다.",
        MessageTypes.ERROR
      );
    }
  });

  const [sendClosingInfo, { client: closingInfoClient }] =
    useSendClosingInfoMutation({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 마감 정보를 전송하지 못했습니다.",
          MessageTypes.ERROR
        );
      },
      update(_, { data }) {
        if (data?.sendClosingInfo.ok) {
          handleToast(
            "성공적으로 마감 정보를 전송하였습니다",
            MessageTypes.SUCCESS
          );
          //   closingInfoClient.resetStore();
        } else if (data?.sendClosingInfo.error) {
          handleToast(data?.sendClosingInfo.error, MessageTypes.ERROR);
        }
      }
    });

  const handleSendClosingInfo = useCallback(
    (employeeIdList: string[]) => {
      if (employeeIdList.length === 0) {
        handleToast("선택된 사원이 없습니다.", MessageTypes.ERROR);
        return;
      }
      sendClosingInfo({
        variables: {
          callEmployeeId,
          employeeIdList,
          startDate,
          endDate
        }
      });
    },
    [sendClosingInfo, startDate, endDate]
  );

  const [sendERPInfo, { client: erpClient }] = useSendErpInfoMutation({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 ERP 정보를 전송하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.sendERPInfo.ok) {
        handleToast(
          "성공적으로 ERP 정보를 전송하였습니다",
          MessageTypes.SUCCESS
        );
        // erpClient.resetStore();
      } else if (data?.sendERPInfo.error) {
        handleToast(data?.sendERPInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const handleSendERPInfo = useCallback(
    (employeeIdList: string[]) => {
      if (employeeIdList.length === 0) {
        handleToast("선택된 사원이 없습니다.", MessageTypes.ERROR);
        return;
      }
      sendERPInfo({
        variables: {
          callEmployeeId,
          employeeIdList,
          startDate,
          endDate
        }
      });
    },
    [sendERPInfo, startDate, endDate]
  );

  const [countUserMonthlyWorkPlan, { data: totalCount }] =
    useCountUserMonthlyWorkPlanLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
      }
    });

  const fetchCountUserMonthlyWorkPlan = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      countUserMonthlyWorkPlan({
        variables: {
          callEmployeeId,
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [countUserMonthlyWorkPlan, selectedListOfEmployeeId, startDate, endDate]);

  const fetchUserMonthlyWorkPlan = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getClosingInfo({
        variables: {
          callEmployeeId,
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate,
          page: currentPage,
          take
        }
      });
    }
  }, [
    getClosingInfo,
    selectedListOfEmployeeId,
    startDate,
    endDate,
    currentPage,
    take,
    callEmployeeId
  ]);

  useEffect(() => {
    if (!isVisibility) return;

    if (
      prevEmployeeIdList.length !== selectedListOfEmployeeId.length ||
      prevEmployeeIdList.some(
        (id, index) => id !== selectedListOfEmployeeId[index]
      ) ||
      retryRender
    ) {
      prevEmployeeIdList = [...selectedListOfEmployeeId];
      fetchCountUserMonthlyWorkPlan();
      fetchUserMonthlyWorkPlan();
      if (retryRender) {
        setRetryRender(false);
      }
    }
  }, [
    selectedListOfEmployeeId,
    retryRender,
    fetchCountUserMonthlyWorkPlan,
    fetchUserMonthlyWorkPlan
  ]);

  useEffect(() => {
    if (!isVisibility) return;

    fetchCountUserMonthlyWorkPlan();
    fetchUserMonthlyWorkPlan();
  }, [isVisibility]);

  const [downloadClosingInfoCsv] = useGetClosingInfoToExcelLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 선택된 월별 근무 계획을 엑셀로 다운로드 하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    onCompleted(data) {
      if (data.getClosingInfoToExcel.ok && data.getClosingInfoToExcel.excel) {
        downloadFileFromServer(
          data.getClosingInfoToExcel.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}_list_of_user_monthly_work.csv`
        );
      } else if (data.getClosingInfoToExcel.error) {
        handleToast(data.getClosingInfoToExcel.error, MessageTypes.ERROR);
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      downloadClosingInfoCsv({
        variables: {
          callEmployeeId,
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [downloadClosingInfoCsv, selectedListOfEmployeeId]);

  return {
    totalCount,
    data,
    loading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    saveSearchDate,
    retryRender,
    setRetryRender,
    downloadExcel,
    currentPage,
    handleCurrentPage,
    take,
    handleTake,
    isToastMessageOpen,
    handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleSendClosingInfo,
    handleSendERPInfo
  };
}

export default useClosingInfoDataFetcher;
